import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SignalService} from "@core/services/signal.service";

export type Language = 'ar' | 'en';

@Injectable({
  providedIn: 'root',
})
export class I18nService {

  constructor(private translateService: TranslateService,
              private signalService: SignalService) {
  }

  setUpLanguages() {
    this.translateService.addLangs(['en', 'ar']);
    this.translateService.setDefaultLang('en');
    this.translateService.use(this.signalService.language());
  }

  translatePhrase(phrase: string, params?: object): string {
    return this.translateService.instant(phrase, params);
  }

  switchLanguages(lang: Language) {
    this.signalService.language.set(lang);
    this.translateService.use(this.signalService.language());
  }

  getCurrentLang(): string {
    return this.translateService.currentLang || this.translateService.getDefaultLang();
  }

  onLangChange() {
    return this.translateService.onLangChange;
  }
}

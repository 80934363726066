export enum CrudEndPoint {
  COMPANY_BRANCH = 'company_branches',
  WASTE_TYPES = 'waste-types',
  COMPANIES = 'companies',
  EMPLOYEES = 'employees',
  BIN_TYPE = 'bin-types',
  POSITIONS = 'positions',
  ROLES = 'roles',
  ACCESSES = 'accesses',
  ROLE_ACCESSES = 'role-accesses',
  FLEET = 'fleets',
  VEHICLE_TYPES = 'vehicle-types',
  FUEL_TYPES = 'fuel-types',
  LOCATION_TYPES = 'location-types',
  SERVICES = 'services',
  VEHICLES = 'vehicles',
  VEHICLES_LOGS = 'vehicles/logs',
  BINS = 'bins',
  TANK_DIMENSIONS = 'tank-dimensions',
  BRANDS = 'brands',
  MODELS = 'models',
  REQUEST_TYPES = 'request-types',
  REQUEST_MODES = 'request-modes',
  LOCATIONS = 'locations',
  DRIVING_LICENSE = 'driving-licenses',
}

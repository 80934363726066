import {effect, Inject, Injectable, PLATFORM_ID, signal, WritableSignal} from '@angular/core';
import {CompanyBranchReference} from "@core/models/company.model";
import {MapType} from "@core/services/map.service";
import {isPlatformBrowser} from "@angular/common";
import {RoleReference} from "@core/models/role.model";
import {StorageKey} from "@core/enums/storage-key";
import {Language} from "@core/services/i18n/i18n.service";

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {
  }

  storedSignal<T>(key: string, initialValue: T): WritableSignal<T> {
    if (isPlatformBrowser(this.platformId)) {
      const storedValue = localStorage.getItem(key);
      const parsedValue = storedValue ? JSON.parse(storedValue) : initialValue;
      const signalData = signal<T>(parsedValue);
      effect(() => {
        const newValue = signalData();
        localStorage.setItem(key, JSON.stringify(newValue));
      });

      return signalData;
    }
    return signal<T>(initialValue)
  }

  branchMapPreferenceSignal = this.storedSignal<CompanyBranchReference | null>(StorageKey.BRANCH_MAP_PREF, null);
  tileSignal = this.storedSignal<MapType>(StorageKey.MAP_TYPE, 'osm');
  authorities = this.storedSignal<string[]>(StorageKey.AUTHORITIES, []);
  role = this.storedSignal<RoleReference | null>(StorageKey.ROLE, null);
  employeeName = this.storedSignal<string | null>(StorageKey.EMPLOYEE_NAME, null);
  profileImageSignal = this.storedSignal<string | null>(StorageKey.PROFILE_IMAGE, null);
  language = this.storedSignal<Language>(StorageKey.LANG, 'en');
}

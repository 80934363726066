import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, finalize, map, Observable, throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ApiErrorResponse, ApiQueryParams, RequestParamType} from '@core/models/api';
import {NotificationService} from "@core/services/notification.service";
import {SpinnerService} from "@core/services/spinner.service";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl: string = environment.serverApiUrl;

  constructor(private httpClient: HttpClient,
              private notificationService: NotificationService,
              private spinnerService: SpinnerService
  ) {
  }

  postImage(url: string, formData: FormData): Observable<string> {
    return this.httpClient
      .post<string>(`${this.apiUrl}/api/${url}`, formData, {responseType: 'text' as 'json', withCredentials: true});
  }

  post<T, U>(url: string, formData: T, displayNotification: boolean = true): Observable<U> {
    return this.httpClient
      .post(`${this.apiUrl}/api/${url}`, formData, {withCredentials: true})
      .pipe(
        map((response) => response as U),
        catchError((err: HttpErrorResponse) => this.handleError(err, displayNotification))
      )
  }

  get<T>(url: string,
         params?: ApiQueryParams,
         observe?: 'response' | 'body',
         displayNotification: boolean = true, showSpinner: boolean = true): Observable<T | HttpResponse<T>> {
    const queryParams = params ? this.buildQueryParameters(params) : undefined;
    if (showSpinner) {
      this.spinnerService.show()
    }
    return this.httpClient
      .get(`${this.apiUrl}/api/${url}`, {
        observe: observe ? observe as 'body' : 'body',
        params: queryParams,
        withCredentials: true
      })
      .pipe(
        map((response) => response as T | HttpResponse<T>),
        catchError((err: HttpErrorResponse) => this.handleError(err, displayNotification)),
        finalize(() => this.spinnerService.hide())
      );
  }

  put<T, U>(url: string, formData: U, displayNotification: boolean = true): Observable<T> {
    return this.httpClient
      .put(`${this.apiUrl}/api/${url}`, formData, {withCredentials: true})
      .pipe(
        map((response) => response as T),
        catchError((err: HttpErrorResponse) => this.handleError(err, displayNotification))
      );
  }

  delete(url: string, displayNotification: boolean = true): Observable<boolean> {
    return this.httpClient
      .delete(`${this.apiUrl}/api/${url}`, {withCredentials: true})
      .pipe(
        map((response) => response as boolean),
        catchError((err: HttpErrorResponse) => this.handleError(err, displayNotification))
      );
  }

  handleError(err: HttpErrorResponse, displayNotification: boolean) {
    const serverError: ApiErrorResponse = err.error;
    if (err.status === 500) {
      console.error(serverError?.properties?.stackTrace);
    }
    if (displayNotification) {
      this.notificationService.onError(serverError?.detail, serverError?.title)
    }
    return throwError(() => err);
  }

  private buildQueryParameters(params: ApiQueryParams): HttpParams {
    let queryParams = new HttpParams();

    if (!params) {
      return queryParams;
    }
    for (const [prop, value] of Object.entries(params)) {
      if (!value) {
        continue;
      }
      if (prop === 'custom') {
        for (const [key, customValue] of Object.entries(value!)) {
          queryParams = queryParams.append(key, customValue as string);
        }
      } else if (prop === 'page' || prop === 'referenceOnly' || prop === 'size' || prop === 'sort') {
        queryParams = queryParams.append(prop, value.toString());
      } else {
        const nestedParams = value as { [key: string]: RequestParamType };
        for (const [key, nestedValue] of Object.entries(nestedParams)) {
          queryParams = queryParams.append(`${key}.${prop}`, nestedValue);
        }
      }
    }
    return queryParams;
  }
}
